<template>
  <div >
   
    
    <v-select  class="mx-auto rounded-xl"
             
             v-model="selectedFilter"
             v-on:input="search()"
              :menu-props="{ offsetY: true }"
              solo
              item-text="filter"
              background-color="secondary lighten-3" 
              hide-details
              :items="filters"
              prepend-inner-icon="mdi-filter"
              label="Sort by"
            ></v-select>

            
  </div>
  
</template>


<script>

import ItemListBuy from "./ItemListBuy.vue";
export default {
  props: ["items"],
  components: { ItemListBuy },
  data: function () {
    return {
      selectedFilter: "All",
    filters: [
          { filter: 'All',  },
          { filter: 'Pickup', },
          { filter: 'Shipping', },
          { filter: 'Trust Price', },
          { filter: 'Reposted', },
           { filter: 'Other TRST Items', },
 
        ],

  };
  },
  
    

  methods: {


    search() {
      console.log(this.selectedFilter);
      if (this.selectedFilter == "Shipping") {
        this.$store.dispatch("setBuyItemList")
     let input = this.$store.state.buyItemList.filter( item.shipping_cost > 0);     
      this.$store.dispatch("filterBuyItemList", input);
      };
       if (this.selectedFilter == "Trust Price") {this.$store.dispatch("setBuyItemList")
     let input = this.$store.state.buyItemList.filter( item.seller == item.creator);     
      this.$store.dispatch("filterBuyItemList", input);
      };
       if (this.selectedFilter == "Reposted") {this.$store.dispatch("setBuyItemList")
     let input = this.$store.state.buyItemList.filter(item.seller != item.creator);     
      this.$store.dispatch("filterBuyItemList", input);
      };
      if (this.selectedFilter == "Pickup") {
        this.$store.dispatch("setLocalBuyItemList"); };
      if (this.selectedFilter == "All") {
        this.$store.dispatch("setBuyItemList"); };
         if (this.selectedFilter == "Other TRST Items") {
       
      window.open('https://marketplace.trustlesshub.com/')
      
         };
      
   
    },

   
  },
};
</script>
