<template>
  <div class="pa-0 pb-4 mx-lg-auto">
    <div>
      <div>
        <div class="py-12 ma-auto align-center">
          <v-row>
            <v-col class="text-center ma-auto" cols="3"
              ><v-img
                src="img/design/watch/2.png "
                max-height="170"
                max-width="90"
                class="rounded-b-xl contain mx-auto"
              >
              </v-img
            ></v-col>
            <v-col @click="exp = !exp" cols="6">
              <h1 class="text-center"><span class="font-weight-bold">Real</span> Watches</h1>
              <h2 class="display-6 text-white text-center font-weight-medium">
                <v-icon size="30">mdi-swap-horizontal </v-icon> Trustless Tokens
              </h2>
              <p class="caption text-center">Trade with independent prices</p>
           
              <p class="caption font-weight-light" v-if="!exp">
                Buying or selling a watch can be difficult. A watch can make you
                feel a certain way. Therefore, use independent information to
                your advantage.. <i style="cursor: pointer"> more</i>
              </p></v-col
            ><v-col cols="3" class="ma-auto"
              ><v-img
                src="img/design/watch/1.png "
                max-height="170"
                contain
                max-width="800"
                class="rounded-b-xl "
              >
              </v-img></v-col
          ></v-row><div   @click="exp = !exp" class="mt--12 ma-12">   <p class="caption font-weight-light" v-if="exp">
                Buying or selling a watch can be difficult. A watch can make you
                feel a certain way. Therefore, use independent information to
                your advantage. Estimators provide useful information and how
                they feel about the watch. When you buy an item, your deposit is
                stored securely on a module account, for free.  Meanwhile,
                you can withdrawl at anytime. After the
                transfer, the seller gets the tokens automatically.On any marketplace, there are
                risks, so it is important to do due dilligence. With information
                on the TRST blockchain, you have insight to the seller's
                previously sold items and the buyer feedback. Plus, if you want
                to deep dive, you can even view transaction histories. <router-link to="/faq"> <span  style="text-decoration: none; color: inherit; ">Learn more</span></router-link>
              </p>
             </div>
        </div>

        <v-container class="mt-n12 mb-n2">
          <v-row>
            <v-col cols="12" sm="7">
              <buy-search-bar />
            </v-col>
            <v-col cols="10" sm="4">
              <buy-options-bar :items="items" />
            </v-col>
            <v-col cols="2" sm="1" class="ml-n1">
              <v-btn fab class="mt-1" small @click="showAdvanced">
                <v-icon> mdi-tune </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <buy-tag-bar :advanced="advanced" />
        </v-container>
        <v-chip-group
          class="mx-4"
          multiple
          show-arrows
          active-class="primary--text"
          ><div v-for="tag in tags" :key="tag">
            <v-chip
              color="primary lighten-2 text-capitalize"
              :to="{ name: 'SearchTag', params: { tag: tag.toLowerCase() } }"
              ><v-icon small left> mdi-tag-outline </v-icon>{{ tag }}
            </v-chip>
          </div>
        </v-chip-group>

        <div v-if="items[0]">
          <div class="pl-4">
            <v-btn icon x-small to="/faq"
              ><v-icon>mdi-information-outline</v-icon>
            </v-btn>
            <span class="caption" v-if="items[1]">
              {{ items.length }} watches available</span
            >
          </div>
          <v-row class="pa-0 ma-0">
            <v-col :key="ii" cols="12" md="6" v-for="(item, ii) in items">
              <v-sheet class="fill-height" color="transparent" min-height="200"
                ><v-lazy
                  v-model="isActive"
                  :options="{
                    threshold: 0.5,
                  }"
                  transition="fade-transition"
                >
                  <buy-item-item-info :itemid="item.id" /></v-lazy
              ></v-sheet>
            </v-col>
          </v-row>
        </div>
        <v-card
          @click="clearList()"
          color="primary lighten-3 "
          class="rounded-xl outlined elevation-1 text-center ma-4 pa-2"
          v-if="items.length < 1"
        >
          <v-card-text class="caption">
            <div class="text-center">
              <img src="img/design/watch/3.png " height="150" />
            </div>
            No watches to show. Use search / filters to find watches. </v-card-text
          ><v-icon> mdi-refresh </v-icon>
        </v-card>
      </div>
    </div>
  </div>
</template>


<script>
import BuyItemItemInfo from "./BuyItemItemInfo.vue";
import BuySearchBar from "./BuySearchBar.vue";
import BuyOptionsBar from "./BuyOptionsBar.vue";
import BuyTagBar from "./BuyTagBar.vue";
import { databaseRef } from "./firebase/db";
export default {
  components: {
    BuyItemItemInfo,
    BuySearchBar,
    BuyOptionsBar,
    BuyTagBar,
  },
  data: function () {
    return {
      advanced: false,
      isActive: false,
      interval: {},
      timeoutvalue: 0,
      exp: false,
      tags: [
        "Rolex",
        "Omega",
        "Submariner",
        "Seamaster",
        "Daytona",
        "Orient",
        "TAG Heuer",
        "Seiko",
        //  "Garden item",

        // "Motor",
        //"Sport",

        // "Antique",
        "Cartier",
        "Other",
      ],
    };
  },

  computed: {
    items() {
      let list = this.$store.getters.getBuyItemList || [];

      if (list[0]) {
        return list;
      } else {
        this.$store.dispatch("setBuyItemList");
        return this.$store.getters.getTotalBuyItems;
      }
    },

    /*   totalItems() {   
       if(this.items){
      return this.$store.state.data.item.length;
       }
    },
  
  onSaleRatio() {
      if(this.items){
      //this.$store.dispatch("setBuyItemList");
      return 100 - (this.totalItems - this.items.length)/this.totalItems*100}else{return 0}
    },*/
  },

  methods: {
    showAdvanced() {
      this.advanced = !this.advanced;

      this.$store.dispatch("setSortedTagList");
      this.$store.dispatch("setSortedLocationList");
    },
    clearList() {
      this.$store.dispatch("setBuyItemList");
    },
  },
};
</script>

<style>
.rotate {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
</style>